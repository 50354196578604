import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { MessageService } from 'primeng/api';
import { GenericService } from 'src/app/controller/services/generic.service';
import { LoginService } from 'src/app/controller/services/login.service';
import { Mensagens } from '../shared/components/mensagens-ptbr';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  //formulário e elementos
  loginForm: FormGroup;
  ano: number;
  hasToken: boolean;
  language: string;

  constructor(
    public translateService: TranslocoService,
    private loginService: LoginService,
    private genericService: GenericService,
    private messageService: MessageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private login: LoginService
  ) { }

  //função que inicia o componente
  ngOnInit() {
    console.log(
    window.open(`${environment.AZURE_REDIRECT}${environment.AZURE_PROJECT}/${environment.AZURE_ENVIRONMENT}`, "_self"))


    this.setUrl();
    this.formComponent();
    let data = new Date();
    this.ano = data.getFullYear();
    this.getSelectLanguage();
  }

  //função que define o tipo de endpoint
  setUrl() {
    this.loginService.tipoUrl('login');
  }

  //função que retorna o idioma definido no localStorage
  getSelectLanguage() {
    if (localStorage.getItem('language') === null) {
      this.translateService.setActiveLang('pt-BR');
      localStorage.setItem('language', 'pt-BR')
    } else {
      this.translateService.setActiveLang(localStorage.getItem('language'));
    }
  }

  //função que retorna o idioma ativo
  getActiveLanguage() {
    this.language = this.translateService.getActiveLang();
  }

  //função que define o formulário 
  formComponent() {
    this.loginForm = new FormGroup({
      username: new FormControl((''), [Validators.required]),
      password: new FormControl((''), [Validators.required]),
      dataHora: new FormControl((''))
    })
  }

  //função que chama a rota para home
  fazerLogin() {
    this.hasToken = true;
    let token = this.activatedRoute.snapshot.params['token']
    this.login.sso(token).subscribe(result =>{
      if (result.body.access_token) {
        this.login.setSession(result.body.access_token);
        this.hasToken = false;
        this.router.navigate(['home/aprovacao']);
      }
    }, error => {
      this.hasToken = false;
      if (error.status === 400) {
        this.messageService.clear();
        this.messageService.add({
          severity: 'error',
          summary: Mensagens.ConnectionErrorSummary,
          detail: Mensagens.ConnectionErrorDetail
        });
      }
      
    })

  }
   
  //define o idioma para pt-BR
  setLanguageBr() {
    this.translateService.setActiveLang('pt-BR');
    /* this.setLanguageSelected('pt-BR'); */
    this.getActiveLanguage();
  }

  //define o idioma para Es
  setLanguageEs() {
    this.translateService.setActiveLang('es');
    this.setLanguageSelected('es');
    this.getActiveLanguage();
  }

  //define o idioma para En
  setLanguageEn() {
    this.translateService.setActiveLang('en');
    this.setLanguageSelected('en');
    this.getActiveLanguage();
  }

  //função que define o serviço padrão selecionado
  setLanguageSelected(element) {
    this.genericService.tipoUrl('language');
    localStorage.setItem('language', element);

    let item = {
      id: 1,
      language_selected: element,
    }

    this.genericService.put(item)
      .subscribe(
        (resp) => {
          this.messageService.clear();
          this.messageService.add({
            severity: 'success',
            summary: Mensagens.AtualizarSummary,
            detail: Mensagens.AtualizarDetail
          });
        }, error => {
          this.messageService.clear();
          this.messageService.add({
            severity: 'error',
            summary: Mensagens.AtualizarErroSummary,
            detail: Mensagens.AtualizarErroDetail
          });
        }
      );
  }

  //função definida para acessar manual
  openLink() {
    window.location.href = 'https://vt.apps.stefanini.io/assets/TutorialVT.pdf';
  }

}

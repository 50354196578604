import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { MenuItem } from 'primeng/api';
import { LoginService } from 'src/app/controller/services/login.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  //menu
  items: MenuItem[];
  language: string;
  sideBar: boolean;

  //usuário
  nome: string;

  constructor(
    private router: Router,
    private loginService: LoginService,
    public translateService: TranslocoService,
  ) { }

  ngOnInit() {
    this.nome = localStorage.getItem('user');
    this.getSelectLanguage();
  }

  //função que retorna o idioma definido no localStorage
  getSelectLanguage() {
    this.translateService.setActiveLang(localStorage.getItem('language'));
  }

  //função que apresenta as opções de seleção de idioma
  setectLanguage() {
    this.sideBar = true;
  }

  //função que fecha o menu
  closeMenu() {
    this.sideBar = false
  }

  //função que realiza o logout da plataforma
  logout() {
    this.router.navigate(['/login']);
    this.loginService.logout();
  }

}

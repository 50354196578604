import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AprovacaoGuard } from './view/aprovacao/aprovacao.guard';
import { AuthGuard } from './view/login/auth.guard';
import { LoginComponent } from './view/login/login.component';
import { HomeComponent } from './view/shared/home/home.component';
import { RedirectComponent } from './view/login/redirect/redirect.component';
import { ForbiddenUserComponent } from './view/login/forbidden-user/forbidden-user.component';

/*  Rotas da Aplicação */
const rotas: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'forbidden', component: ForbiddenUserComponent },
  { path: 'redirect/:token', component: RedirectComponent},
  {
    path: 'home', component: HomeComponent, canActivate: [AuthGuard],
    children: [
      { path: 'aprovacao', loadChildren: './view/aprovacao/aprovacao.module#AprovacaoModule', canActivate: [AprovacaoGuard] },
      
    ]
  },
];

@NgModule({
  imports: [ 
    CommonModule,
    RouterModule.forRoot(rotas, { useHash: true, relativeLinkResolution: 'legacy' })
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forbidden-user',
  templateUrl: './forbidden-user.component.html',
  styleUrls: ['./forbidden-user.component.scss']
})
export class ForbiddenUserComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    localStorage.removeItem('@token:azure');
  }

  back(){
    this.router.navigate(['/login']);
  }

}

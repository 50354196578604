import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subject, Subscription} from 'rxjs';
import { LoginService } from 'src/app/controller/services/login.service';
import { Mensagens } from '../../shared/components/mensagens-ptbr';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit{

  onDestroyer$ = new Subject();
	subscription: Subscription = new Subscription();

  hasToken: boolean = true
  
  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private login: LoginService,
              private messageService: MessageService) { }

  ngOnInit(): void {

    let token = this.activatedRoute.snapshot.params['token']
    this.login.sso(token).subscribe(result =>{
      if (result.body.access_token) {
        this.login.setSession(result.body.access_token);
        this.hasToken = false;
        this.router.navigate(['home/aprovacao']);
      }
    }, error => {
      this.hasToken = false;
      if (error.status === 401) {
        this.messageService.clear();
        this.messageService.add({
          severity: 'error',
          summary: Mensagens.ConnectionErrorSummary,
          detail: Mensagens.ConnectionErrorDetail
        });
      }
    })
    
  }
  
}

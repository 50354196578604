import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { CommonServices } from 'src/app/controller/services/common.service';
import { GenericService } from 'src/app/controller/services/generic.service';
import { LoginService } from 'src/app/controller/services/login.service';
import { Mensagens } from '../shared/components/mensagens-ptbr';

@Component({
  selector: 'app-aprovacao',
  templateUrl: './aprovacao.component.html',
  styleUrls: ['./aprovacao.component.css']
})
export class AprovacaoComponent implements OnInit {

  //forms
  language: string;
  perfil: any;
  cadastroVigenciaForm: FormGroup;
  cadastroUsuarioForm: FormGroup;
  buscarForm: FormGroup;

  //modelo
  pessoas: any[];
  vigencias: any[];
  usuarios: any[];
  isCPF: boolean;
  colaborador: any;

  //dialogs
  vigenciaId: any;
  usuarioId: any;
  usuarioAd: any;
  usuarioEmail: any;

  //tabela
  cols: any[];
  cols2: any[];
  cols3: any[];
  titleExport: string;

  //Datas
  minDate: string;
  maxDate: string;

  //mensagens
  msgs1: Message[];

  //booleans
  atualizacao: string;
  isError: boolean;
  checked: boolean;
  displayUpdateList: boolean;
  isConfirm: boolean;

  //vigência
  dtInit: string;
  dtFim: string;
  isEdicao: boolean;
  displayVigencia: boolean;
  displayVigenciaExcluir: boolean;
  isLoadingButton: boolean;

  //usuario
  isEdicaoUsuario: boolean;
  displayUsuario: boolean;
  displayUsuarioExcluir: boolean;
  displayVincular: boolean;
  isLoadingButtonUsuario: boolean;
  isLoadingButtonVinculo: boolean;
  isLoadingButtonCondirmarVinculo: boolean;
  isLoadingButtonCondirmarUpdate: boolean;

  //selects
  perfis: any[];
  responsaveis: any[];
  optionsVale: SelectItem[];
  qtdDias: SelectItem[];

  constructor(
    public translateService: TranslocoService,
    private commonServices: CommonServices,
    private genericService: GenericService,
    private messageService: MessageService,
    private loginService: LoginService,
    private router: Router
  ) {
  }

  //função que inicia o componente
  ngOnInit() {
    this.getActiveLanguage();
    this.getTableInfo();
    this.setParamsTable();
    this.formComponent();
    this.setParamsSelect();
    this.showMessages();
  }

  //função que define o formulário 
  formComponent() {
    this.cadastroVigenciaForm = new FormGroup({
      vigencia: new FormControl((''), [Validators.required]),
      dtVigenciaDe: new FormControl((''), [Validators.required]),
      dtVigenciaAte: new FormControl((''), [Validators.required]),
    });

    this.cadastroUsuarioForm = new FormGroup({
      usuario: new FormControl((''), [Validators.required]),
      email: new FormControl((''), [Validators.required]),
      id_coligada: new FormControl((''), [Validators.required]),
      id_celula: new FormControl((''), [Validators.required]),
      desc_usuario: new FormControl((''), [Validators.required]),
      email_responsavel: new FormControl(('')),
    });

    this.buscarForm = new FormGroup({
      cpf: new FormControl((''), [Validators.required]),
    });

    this.perfil = localStorage.getItem('perfil');
  }

  //função que retorna a atualização
  getAtualizacao() {
    let today = new Date();
    let dd = String(today.getDate());
    let mm = String(today.getMonth() + 1);
    let yyyy = today.getFullYear();
    let hour = String(today.getHours());
    let minute = String(today.getMinutes());
    let seconds = String(today.getSeconds());
    let milliSeconds = String(today.getMilliseconds());
    let formatedHour = String(hour) + ":" + String(minute) + ":" + String(seconds) + "." + String(milliSeconds);

    this.atualizacao = yyyy + '-' + mm + '-' + dd + ' ' + formatedHour;
  }

  //função que exibe as mensagens
  showMessages() {

    if (this.isConfirm && this.perfil !== 'Administrador') {
      this.msgs1 = [
        { severity: 'info', summary: 'Info', detail: 'A compra de vale transporte é simples e fácil: o botão habilitado em azul indica a compra do VT para a vigência (SIM). Em cinza, significa não comprar (NÃO). Em caso de dúvidas, você pode consultar no botão abaixo o manual do usuário.' },
        { severity: 'warn', summary: 'Atenção', detail: 'Caso algum colaborador não apareça na listagem, utilize o botão vincular para adicioná-lo a sua visão. Em último caso, pode ser que não seja um optante pelo VT, para resolver basta abrir um chamado via Stefanini Atende para solicitação. Importante: Mantenha as informações atualizadas sobre sua hierarquia no STFCORP.' },
        { severity: 'success', summary: 'Concordo', detail: 'As interações nessa aplicação são de responsabilidade do usuário, a solicitação de compra também, sendo assim se faz necessário a sua devida autorização, para isso você deverá clicar na caixa de seleção "CONCORDO" e confirmar a atualização.' },
      ];
    } else if (!this.isConfirm && this.perfil !== 'Administrador') {
      this.msgs1 = [
        { severity: 'info', summary: 'Info', detail: 'A compra de vale transporte é simples e fácil: o botão habilitado em azul indica a compra do VT para a vigência (SIM). Em cinza, significa não comprar (NÃO). Em caso de dúvidas, você pode consultar no botão abaixo o manual do usuário.' },
        { severity: 'warn', summary: 'Atenção', detail: 'Caso algum colaborador não apareça na listagem, utilize o botão vincular para adicioná-lo a sua visão. Em último caso, pode ser que não seja um optante pelo VT, para resolver basta abrir um chamado via Stefanini Atende para solicitação. Importante: Mantenha as informações atualizadas sobre sua hierarquia no STFCORP.' }
      ];
    } else {
      this.msgs1 = [
        { severity: 'info', summary: 'Info', detail: 'A compra de vale transporte é simples e fácil: o botão habilitado em azul indica a compra do VT para a vigência (SIM). Em cinza, significa não comprar (NÃO). Em caso de dúvidas, você pode consultar no botão abaixo o manual do usuário.' },
        { severity: 'warn', summary: 'Atenção', detail: 'Caso algum colaborador não apareça na listagem, utilize o botão vincular para adicioná-lo a sua visão. Em último caso, pode ser que não seja um optante pelo VT, para resolver basta abrir um chamado via Stefanini Atende para solicitação. Importante: Mantenha as informações atualizadas sobre sua hierarquia no STFCORP.' }
      ];
    }

  }

  //função que define os parâmetros para a criação da tabela
  setParamsTable() {
    this.cols = [
      { field: 'nmProfissional', header: 'Nome do Profissional', width: '25%', align: 'left' },
      { field: 'nome_fantasia', header: 'Cliente', width: '20%', align: 'left' },
      { field: 'nome_servico', header: 'Serviço', width: '25%', align: 'left' },
      { field: 'dtAtualizacao', header: 'Dt Atualização', width: '15%', align: 'center' },
      /* { field: 'id_coligada', header: 'Coligada', width: '15%', align: 'center' }, */
      { field: 'atualizadoPor', header: 'Atualizado por', width: '15%', align: 'center' },
      /* { field: 'id_Profissional_Gerente', header: 'Gerente', width: '10%', align: 'center' }, */
      /* { field: 'optante_vale_transporte_para', header: 'VT', width: '10%', align: 'center' }, */
    ];

    this.cols2 = [
      { field: 'vigencia', header: 'Vigência', width: '25%', align: 'left' },
      { field: 'dtVigenciaDe', header: 'Início', width: '15%', align: 'left' },
      { field: 'dtVigenciaAte', header: 'Fim', width: '15%', align: 'left' },
    ];

    this.cols3 = [
      { field: 'usuario', header: 'Usuário', width: '15%', align: 'left' },
      { field: 'email', header: 'Email', width: '25%', align: 'left' },
      { field: 'id_coligada', header: 'Coligada', width: '15%', align: 'center' },
      { field: 'id_celula', header: 'Célula', width: '15%', align: 'center' },
      { field: 'desc_usuario', header: 'Perfil', width: '15%', align: 'center' },
      { field: 'email_responsavel', header: 'Responsável', width: '25%', align: 'left' },
    ];

    if (localStorage.getItem('primeiro_acesso') === 'true') {
      this.isConfirm = true;
    } else if (localStorage.getItem('primeiro_acesso') === 'false') {
      this.isConfirm = false;
    }
  }

  //função que define o perfil dos usuários cadastrados
  setParamsSelect() {
    if (this.perfil === 'Diretor') {
      if (this.language === 'en') {
        this.perfis = [
          { label: 'Diretor', value: 'Diretor' },
          { label: 'Gestor', value: 'Gestor' },
        ];
      } else if (this.language === 'es') {
        this.perfis = [
          { label: 'Diretor', value: 'Diretor' },
          { label: 'Gestor', value: 'Gestor' },
        ];
      } else if (this.language === 'pt-BR') {
        this.perfis = [
          { label: 'Diretor', value: 'Diretor' },
          { label: 'Gestor', value: 'Gestor' },
        ];
      }
    } if (this.perfil === 'Administrador') {
      if (this.language === 'en') {
        this.perfis = [
          { label: 'Administrador', value: 'Administrador' },
          { label: 'Diretor', value: 'Diretor' },
          { label: 'Gestor', value: 'Gestor' },
        ];
      } else if (this.language === 'es') {
        this.perfis = [
          { label: 'Administrador', value: 'Administrador' },
          { label: 'Diretor', value: 'Diretor' },
          { label: 'Gestor', value: 'Gestor' },
        ];
      } else if (this.language === 'pt-BR') {
        this.perfis = [
          { label: 'Administrador', value: 'Administrador' },
          { label: 'Diretor', value: 'Diretor' },
          { label: 'Gestor', value: 'Gestor' },
        ];
      }
    }
    
    this.optionsVale = [
      { label: 'Sim', value: true },
      { label: 'Flexível', value: 'flex' },
    ];

    this.qtdDias = [
      /* { label: '1', value: "1" },
      { label: '2', value: "2" },
      { label: '3', value: "3" },
      { label: '4', value: "4" }, */
      { label: '5', value: 5 },
      { label: '6', value: 6 },
      { label: '7', value: 7 },
      { label: '8', value: 8 },
      { label: '9', value: 9 },
      { label: '10', value: 10 },
      { label: '11', value: 11 },
      { label: '12', value: 12 },
      { label: '13', value: 13 },
      { label: '14', value: 14 },
      { label: '15', value: 15 },
      { label: '16', value: 16 },
    ];
  }

  //função que consulta as informações cadastradas
  getTableInfo() {
    let item = {
      cpf: localStorage.getItem('cpf'),
      perfil: localStorage.getItem('perfil'),
      email: localStorage.getItem('email')
    }
    this.isError = false;
    this.genericService.tipoUrl('vt/colaborador/select');
    this.genericService.post(item)
      .subscribe(
        (resp) => {
          if (resp.body === undefined) {
            this.pessoas = [];
          } else {
            this.pessoas = resp.body;
            this.pessoas = this.pessoas.map(i =>
              i.qtde_dias > 0 ? { ...i, vale_transporte: 'flex' } : i
            );
            this.getTableInfoVigencia();
          }
        }, error => {
          this.messageService.clear();
          this.messageService.add({
            severity: 'warn',
            summary: Mensagens.ConnectionErrorSummary,
            detail: Mensagens.ConnectionErrorDetail
          });
          this.isError = true;
        });
  }

  //função que consulta as informações cadastradas
  getTableInfoVigencia() {
    this.genericService.tipoUrl('vt/vigencia/select');
    this.genericService.get()
      .subscribe(
        (resp) => {
          if (resp.body === undefined) {
            this.vigencias = [];
          } else {
            this.vigencias = resp.body;
            this.dtInit = resp.body[0].dtVigenciaDe;
            this.dtFim = resp.body[0].dtVigenciaAte;
            this.isError = false;
            this.getTableInfoUsuario();
          }
        }, error => {
          this.messageService.clear();
          this.messageService.add({
            severity: 'warn',
            summary: Mensagens.ConnectionErrorSummary,
            detail: Mensagens.ConnectionErrorDetail
          });
          this.isError = true;
        });
  }

  //função que consulta as informações cadastradas - Usuário
  getTableInfoUsuario() {
    this.genericService.tipoUrl('vt/usuario/select');
    let item = {
      email: localStorage.getItem('email'),
      perfil: localStorage.getItem('perfil')
    }
    this.genericService.post(item)
      .subscribe(
        (resp) => {
          if (resp.body === undefined) {
            this.usuarios = [];
          } else {
            this.usuarios = resp.body;
            this.isError = false;
            this.getResponsaveis();
          }
        }, error => {
          this.messageService.clear();
          this.messageService.add({
            severity: 'warn',
            summary: Mensagens.ConnectionErrorSummary,
            detail: Mensagens.ConnectionErrorDetail
          });
          this.isError = true;
        });
  }

  //função que retorna a lista de responsáveis
  getResponsaveis() {
    this.genericService.tipoUrl('vt/usuario/responsaveis');
    this.genericService.get()
      .subscribe(
        (resp) => {
          if (resp.body === undefined) {
            this.responsaveis = [];
          } else {
            this.responsaveis = resp.body
          }
        }, error => {
          this.messageService.clear();
          this.messageService.add({
            severity: 'warn',
            summary: Mensagens.ConnectionErrorSummary,
            detail: Mensagens.ConnectionErrorDetail
          });
          this.isError = true;
        });
  }

  //função que valida o usuário informado
  getUsuarioAd() {
    this.genericService.tipoUrl('vt/usuario/ad');
    let usuario = {
      user: this.cadastroUsuarioForm.value.usuario
    }
    this.genericService.post(usuario)
      .subscribe(
        (resp) => {
          this.usuarioAd = resp.body;
          this.cadastroUsuarioForm.controls.email.setValue(this.usuarioAd.usuario_email);
          this.cadastroUsuarioForm.controls.id_coligada.setValue(this.usuarioAd.id_empresa);
          this.cadastroUsuarioForm.controls.id_celula.setValue(this.usuarioAd.id_celula);
        }, error => {
          this.messageService.clear();
          this.messageService.add({
            severity: 'warn',
            detail: Mensagens.NotFoundErrorDetail
          });
          this.cadastroUsuarioForm.controls.usuario.reset();
          this.cadastroUsuarioForm.controls.id_coligada.reset();
          this.cadastroUsuarioForm.controls.id_celula.reset();
          this.cadastroUsuarioForm.controls.email.reset();
        });
  }

  //função que altera a autorização do usuário 
  authorizationChange(event, element, dias) {
    this.getAtualizacao();
    let vtSelect;
    let qtd_dias;
    let item;
    let setvalue = false;

    if (event === true) {
      vtSelect = 'S';
      setvalue = true;
      qtd_dias = 0;
      element.qtde_dias = null;
    } else if (event === false) {
      vtSelect = 'N';
      qtd_dias = 0;
      setvalue = true;
      element.qtde_dias = null;
    } else if (event === 'flex' && !!dias) {
      vtSelect = 'F';
      qtd_dias = dias;
      setvalue = true;
    }

    item = {
      atualizadoPor: localStorage.getItem('userAd'),
      dtAtualizacao: this.atualizacao,
      id_celula: element.id_celula,
      id_coligada: element.id_coligada,
      id_profissional_gerente: element.id_profissional_gerente,
      matricula: element.matricula,
      nmProfissional: element.nmProfissional,
      vale_transporte: vtSelect,
      qtde_dias: qtd_dias ? qtd_dias : 0,
      cpf: element.cpf
    }

    if (setvalue) {
      this.setValueAuthorization(item);
    }
  }

  setValueAuthorization(item) {
    this.genericService.tipoUrl('vt/colaborador/update');
    this.genericService.post(item)
      .subscribe(
        (resp) => {
          /* this.getTableInfo(); */
        }, error => {
          this.messageService.clear();
          this.messageService.add({
            severity: 'warn',
            summary: Mensagens.ConnectionErrorSummary,
            detail: Mensagens.ConnectionErrorDetail
          });
        });
  }

  //função que retorna o idioma ativo
  getActiveLanguage() {
    this.language = this.translateService.getActiveLang();
  }

  //função que habilita a adição de vigência
  setAdicionarVigencia() {
    this.isEdicao = false;
    this.displayVigencia = true;
    this.isLoadingButton = false;
    this.cadastroVigenciaForm.reset();
  }

  //função que habilita a adição de vigência - Usuário
  setAdicionarUsuario() {
    this.isEdicaoUsuario = false;
    this.displayUsuario = true;
    this.isLoadingButton = false;
    this.cadastroUsuarioForm.reset();
  }

  //função que seta os parâmetros para edição
  setEditarVigencia(item) {
    this.cadastroVigenciaForm.controls.vigencia.setValue(item.vigencia);
    this.vigenciaId = item.vigencia;
    this.cadastroVigenciaForm.controls.dtVigenciaDe.setValue(item.dtVigenciaDe_raw);
    this.cadastroVigenciaForm.controls.dtVigenciaAte.setValue(item.dtVigenciaAte_raw);
    this.displayVigencia = true;
    this.isLoadingButton = false;
    this.isEdicao = true;
  }

  //função que seta os parâmetros para edição - Usuário
  setEditarUsuario(item) {
    this.cadastroUsuarioForm.reset();
    this.cadastroUsuarioForm.controls.usuario.setValue(item.usuario);
    this.usuarioId = item.usuario;
    this.usuarioAd = item.usuario;
    this.cadastroUsuarioForm.controls.id_coligada.setValue(item.id_coligada);
    this.cadastroUsuarioForm.controls.id_celula.setValue(item.id_celula);
    this.cadastroUsuarioForm.controls.email.setValue(item.email);
    this.cadastroUsuarioForm.controls.desc_usuario.setValue(item.desc_usuario);
    this.cadastroUsuarioForm.controls.email_responsavel.setValue(item.email_responsavel);
    this.displayUsuario = true;
    this.isLoadingButtonUsuario = false;
    this.isEdicaoUsuario = true;

    if (this.cadastroUsuarioForm.controls.desc_usuario.value !== 'Gestor') {
      this.cadastroUsuarioForm.controls.email_responsavel.disable();
    } else {
      this.cadastroUsuarioForm.controls.email_responsavel.enable();
    }
  }

  //função que seta os parâmetros para remoção
  setRemoverVigencia(item) {
    this.vigenciaId = item.vigencia;
    this.displayVigenciaExcluir = true;
    this.isEdicao = false;
  }

  //função que seta os parâmetros para remoção
  setRemoverUsuario(item) {
    this.usuarioId = item.usuario;
    this.usuarioEmail = item.email;
    this.displayUsuarioExcluir = true;
    this.isEdicaoUsuario = false;
  }

  //função para exportar a tabela
  exportExcel() {
    this.setName();
    this.commonServices.exportExcel(this.pessoas, this.titleExport);
  }

  //função para definir o nome da tabela
  setName() {
    if (this.language === 'en') {
      this.titleExport = 'List of Collaborators VT';
    } else if (this.language === 'es') {
      this.titleExport = 'Lista de Colaboradores VT';
    } else if (this.language === 'pt-BR') {
      this.titleExport = 'Lista de Colaboradores VT';
    }
  }

  //função que seleciona o item no dropdown
  onChangePerfil(element) {
    this.cadastroUsuarioForm.controls.desc_usuario.setValue(element.value);
    if (element.value !== 'Gestor') {
      this.cadastroUsuarioForm.controls.email_responsavel.reset();
      this.cadastroUsuarioForm.controls.email_responsavel.disable();
    } else {
      this.cadastroUsuarioForm.controls.email_responsavel.enable();
    }
  }

  //função que seleciona o item no dropdown
  onChangeResponsavel(element) {
    this.cadastroUsuarioForm.controls.email_responsavel.setValue(element.value);
  }

  //função que atualiza o valor mínimo da data
  dataIniChange() {
    if (this.cadastroVigenciaForm.value.dtVigenciaDe) {
      this.minDate = this.cadastroVigenciaForm.value.dtVigenciaDe;
    } else {
      this.minDate = this.commonServices.minDate;
    }
  }

  //função que atualiza o valor máximo da data
  dataFimChange() {
    if (this.cadastroVigenciaForm.value.dtVigenciaDe) {
      this.minDate = this.cadastroVigenciaForm.value.dtVigenciaDe;
    } else {
      this.minDate = this.commonServices.maxDate;
    }
  }

  //função que restringe o compo apenas a números
  onlyNumber(event): boolean {
    return this.commonServices.numberOnly(event);
  }

  //função para adicionar o elemento selecionado
  salvarVigencia() {
    this.isLoadingButton = true;
    this.genericService.urlService = 'vt/vigencia/insert';
    this.cadastroVigenciaForm.controls.vigencia.setValue(this.cadastroVigenciaForm.controls.vigencia.value.replace('-', ''))
    var addVigencia = this.cadastroVigenciaForm.value;
    this.genericService.post(addVigencia)
      .subscribe(
        (resp) => {
          this.cadastroVigenciaForm.reset();
          this.displayVigencia = false;
          this.isLoadingButton = false;
          this.getTableInfoVigencia();

          this.messageService.clear();
          this.messageService.add({
            severity: 'success',
            summary: Mensagens.CadastroSummary,
            detail: Mensagens.CadastroDetail
          });
        }, error => {
          this.isLoadingButton = false;
          this.messageService.clear();
          this.messageService.add({
            severity: 'error',
            summary: Mensagens.CadastroErroSummary,
            detail: Mensagens.CadastroErroDetail
          });
        }
      );
  }

  //função para adicionar o elemento selecionado
  salvarUsuario() {
    this.isLoadingButtonUsuario = true;
    this.genericService.urlService = 'vt/usuario/insert';
    var addUsuario = this.cadastroUsuarioForm.value;
    this.genericService.post(addUsuario)
      .subscribe(
        (resp) => {
          this.cadastroUsuarioForm.reset();
          this.displayUsuario = false;
          this.isLoadingButtonUsuario = false;
          this.getTableInfoUsuario();

          this.messageService.clear();
          this.messageService.add({
            severity: 'success',
            summary: Mensagens.CadastroSummary,
            detail: Mensagens.CadastroDetail
          });
        }, error => {
          if (error.status === 409) {
            this.isLoadingButtonUsuario = false;
            this.messageService.clear();
            this.messageService.add({
              severity: 'warn',
              summary: Mensagens.CadastroErroSummary,
              detail: Mensagens.UsuarioErrorDetail
            });
            this.cadastroUsuarioForm.controls.usuario.reset();
            this.cadastroUsuarioForm.controls.id_coligada.reset();
            this.cadastroUsuarioForm.controls.id_celula.reset();
            this.cadastroUsuarioForm.controls.email.reset();
          } else {
            this.isLoadingButtonUsuario = false;
            this.messageService.clear();
            this.messageService.add({
              severity: 'error',
              summary: Mensagens.CadastroErroSummary,
              detail: Mensagens.CadastroErroDetail
            });
          }
        }
      );
  }

  //função para alterar o elemento selecionado
  editarVigencia() {
    this.isLoadingButton = true;
    this.genericService.urlService = 'vt/vigencia/update';
    this.cadastroVigenciaForm.controls.vigencia.setValue(this.cadastroVigenciaForm.controls.vigencia.value.replace('-', ''))
    var addVigencia = this.cadastroVigenciaForm.value;
    this.genericService.post(addVigencia)
      .subscribe(
        (resp) => {
          this.messageService.clear();
          this.messageService.add({
            severity: 'success',
            summary: Mensagens.AtualizarSummary,
            detail: Mensagens.AtualizarDetail
          });
          this.cadastroVigenciaForm.reset();
          this.displayVigencia = false;
          this.isLoadingButton = false;
          this.getTableInfoVigencia();
        }, error => {
          this.isLoadingButton = false;
          this.messageService.clear();
          this.messageService.add({
            severity: 'error',
            summary: Mensagens.AtualizarErroSummary,
            detail: Mensagens.AtualizarErroDetail
          });
        }
      );
  }

  //função para alterar o elemento selecionado - Usuário
  editarUsuario() {
    this.isLoadingButtonUsuario = true;
    this.genericService.urlService = 'vt/usuario/update';
    var addUsuario = this.cadastroUsuarioForm.value;
    this.genericService.post(addUsuario)
      .subscribe(
        (resp) => {
          this.messageService.clear();
          this.messageService.add({
            severity: 'success',
            summary: Mensagens.AtualizarSummary,
            detail: Mensagens.AtualizarDetail
          });
          this.cadastroUsuarioForm.reset();
          this.displayUsuario = false;
          this.isLoadingButtonUsuario = false;
          this.getTableInfoUsuario();
        }, error => {
          if (error.status === 400) {
            this.isLoadingButtonUsuario = false;
            this.messageService.clear();
            this.messageService.add({
              severity: 'warn',
              summary: Mensagens.AtualizarErroSummary,
              detail: Mensagens.UsuarioErrorDetail
            });
          } else {
            this.isLoadingButtonUsuario = false;
            this.messageService.clear();
            this.messageService.add({
              severity: 'error',
              summary: Mensagens.AtualizarErroSummary,
              detail: Mensagens.AtualizarErroDetail
            });
          }
        }
      );
  }

  //função para remover o elemento selecionado
  excluirVigencia() {
    let item = {
      vigencia: this.vigenciaId
    }
    this.genericService.urlService = 'vt/vigencia/delete';
    this.genericService.post(item)
      .subscribe(
        (resp) => {
          this.messageService.clear();
          this.messageService.add({
            severity: 'success',
            summary: Mensagens.ExcluirSummary,
            detail: Mensagens.ExcluirDetail
          });
          this.displayVigenciaExcluir = false;
          this.getTableInfoVigencia();
        }, error => {
          this.messageService.clear();
          this.messageService.add({
            severity: 'error',
            summary: Mensagens.ExcluirErroSummary,
            detail: Mensagens.ExcluirErroDetail
          });
        }
      );
  }

  //função para remover o elemento selecionado - Usuário
  excluirUsuario() {
    let item = {
      email: this.usuarioEmail
    }
    this.genericService.urlService = 'vt/usuario/delete';
    this.genericService.post(item)
      .subscribe(
        (resp) => {
          this.messageService.clear();
          this.messageService.add({
            severity: 'success',
            summary: Mensagens.ExcluirSummary,
            detail: Mensagens.ExcluirDetail
          });
          this.displayUsuarioExcluir = false;
          this.getTableInfoUsuario();
        }, error => {
          if (error.status === 403) {
            this.messageService.clear();
            this.messageService.add({
              severity: 'warn',
              summary: Mensagens.ExcluirErroSummary,
              detail: Mensagens.UsuarioExcluirErroDetail
            });
          } else {
            this.messageService.clear();
            this.messageService.add({
              severity: 'error',
              summary: Mensagens.ExcluirErroSummary,
              detail: Mensagens.ExcluirErroDetail
            });
          }
        }
      );
  }

  //função que baixa a planilha de vigências
  infoPlanilhaVigencia(element) {
    let item = {
      vigencia: element.vigencia
    }
    this.genericService.urlService = 'vt/vigencia/csv';
    this.genericService.post(item)
      .subscribe(
        (resp) => {
          if (resp.body.length > 0) {
            this.setName();
            this.commonServices.exportExcel(resp.body, this.titleExport);
          } else {
            this.messageService.clear();
            this.messageService.add({
              severity: 'warn',
              detail: Mensagens.NotItensFound
            });
          }
        }, error => {
          this.messageService.clear();
          this.messageService.add({
            severity: 'warn',
            summary: Mensagens.ConnectionErrorSummary,
            detail: Mensagens.ConnectionErrorDetail
          });
        }
      );
  }

  //função que cancela a interação de adição/edição da vigência
  cancelarVigencia() {
    this.isEdicao = false;
    this.displayVigencia = false;
    this.isLoadingButton = false;
    this.cadastroVigenciaForm.reset();
    this.getAtualizacao();
    this.atualizacao;
  }

  //função que cancela a interação de adição/edição da usuário
  cancelarUsuario() {
    this.isEdicaoUsuario = false;
    this.displayUsuario = false;
    this.isLoadingButtonUsuario = false;
    this.cadastroUsuarioForm.reset();
  }

  //função que cancela a interação de exclusao da vigência
  cancelarExclusao() {
    this.displayVigenciaExcluir = false;
  }

  //função que cancela a interação de exclusao da usuário
  cancelarExclusaoUsuario() {
    this.displayUsuarioExcluir = false;
  }

  //função para abrir modal para vinculação
  setAdicionarVinculo() {
    this.displayVincular = true;
    this.isLoadingButtonVinculo = false;
    this.isLoadingButtonCondirmarVinculo = false;
    this.buscarForm.reset();
    this.colaborador = null;
    this.isCPF = false;
  }

  //função que verifica o campo do cpf
  checkCPF(event) {
    if (this.buscarForm.controls.cpf.value.replace(/\.|-/gm, '').replace("_", "").trim().length === 11) {
      this.isCPF = true;
    }
    else {
      this.isCPF = false;
    }
  }

  //função para consultar profissional
  buscarColaborador() {
    this.isLoadingButtonVinculo = true;
    this.genericService.tipoUrl('vt/usuario/vinculo');
    this.genericService.getById(this.buscarForm.controls.cpf.value.replace(/\.|-/gm, ''))
      .subscribe(
        (resp) => {
          this.messageService.clear();
          this.messageService.add({
            severity: 'success',
            summary: Mensagens.BuscarSummary,
            detail: Mensagens.BuscarDetail
          });
          this.colaborador = resp.body[0];
          this.isLoadingButtonVinculo = false;
        }, error => {
          this.isLoadingButtonVinculo = false;

          if (error.status === 404) {
            this.messageService.clear();
            this.messageService.add({
              severity: 'warn',
              summary: Mensagens.BuscarErroSummary,
              detail: Mensagens.BuscarErroDetail
            });
          }
          else if (error.status === 403) {
            this.messageService.clear();
            this.messageService.add({
              severity: 'warn',
              summary: Mensagens.BuscarAlertaNaoOptanteSummary,
              detail: Mensagens.BuscarAlertaNaoOptanteDetail
            });
          } else {
            this.messageService.clear();
            this.messageService.add({
              severity: 'error',
              summary: Mensagens.ConnectionErrorSummary,
              detail: Mensagens.ConnectionErrorDetail
            });
          }
        });
  }

  //função que baixa a planilha de vigências
  salvarVinculacao() {
    this.isLoadingButtonCondirmarVinculo = true;
    let item = {
      cpf: this.buscarForm.controls.cpf.value.replace(/\.|-/gm, ''),
      cpfVinculado: localStorage.getItem('cpf')
    }
    this.genericService.urlService = 'vt/usuario/cadastro/vinculo';
    this.genericService.post(item)
      .subscribe(
        (resp) => {
          this.messageService.clear();
          this.messageService.add({
            severity: 'success',
            summary: Mensagens.VincularSummary,
            detail: Mensagens.VincularDetail
          });
          this.displayVincular = false;
          this.isLoadingButtonCondirmarVinculo = false;
          this.getTableInfo();
        }, error => {
          this.isLoadingButtonCondirmarVinculo = false;

          this.messageService.clear();
          this.messageService.add({
            severity: 'error',
            summary: Mensagens.VincularErroSummary,
            detail: Mensagens.VincularErroDetail
          });
        }
      );
  }

  //função que cancela o vínculo
  cancelarVinculo() {
    this.displayVincular = false;
    this.buscarForm.reset();
    this.colaborador = [];
  }

  //função que baixa a planilha de vigências
  infoPlanilhColaboradores() {
    this.genericService.urlService = 'vt/colaborador/planilha';
    let item = {
      cpf: localStorage.getItem('cpf'),
      perfil: localStorage.getItem('perfil'),
      email: localStorage.getItem('email')
    }
    this.genericService.post(item)
      .subscribe(
        (resp) => {
          if (resp.body.length > 0) {
            this.setName();
            this.commonServices.exportExcel(resp.body, this.titleExport);
          }
        }, error => {
          this.messageService.clear();
          this.messageService.add({
            severity: 'warn',
            summary: Mensagens.ConnectionErrorSummary,
            detail: Mensagens.ConnectionErrorDetail
          });
        }
      );
  }

  //função definida para acessar manual
  openLink() {
    window.location.href = 'https://vt.apps.stefanini.io/assets/TutorialVT.pdf';
  }

  //função que habilita a seleção de atualização
  updateList() {
    this.displayUpdateList = true;
  }

  //função que cancela a solicitação de atualização
  cancelarUpdate() {
    this.checked = false;
    this.displayUpdateList = false;
  }

  //função que confirma a solicitação de atualização
  confirmarUpdate() {
    this.isLoadingButtonCondirmarUpdate = true;

    let dataHora = new Date();

    this.genericService.urlService = 'vt/update/geral';
    let item = {
      cpf: localStorage.getItem('cpf'),
      dataHora: dataHora.toISOString(),
      email: localStorage.getItem('email')
    }
    this.genericService.post(item)
      .subscribe(
        (resp) => {
          this.messageService.clear();
          this.messageService.add({
            severity: 'success',
            summary: Mensagens.UpdateListSummary,
            detail: Mensagens.UpdateListDetail
          });

          this.checked = true;
          this.displayUpdateList = false;
          this.isConfirm = false;
          this.isLoadingButtonCondirmarUpdate = false;
          localStorage.setItem('primeiro_acesso', 'false');

          location.reload();
        }, error => {
          this.messageService.clear();
          this.messageService.add({
            severity: 'error',
            summary: Mensagens.UpdateListErrorSummary,
            detail: Mensagens.UpdateListErrorDetail
          });

          this.checked = true;
          this.displayUpdateList = true;
          this.isConfirm = true;
          this.isLoadingButtonCondirmarUpdate = false;
        }
      );
  }

  //função que retorna a tela de card
  voltar() {
    this.router.navigate(['/login']);
    this.loginService.logout();
  }

}

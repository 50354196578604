import { CommonModule, registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoRootModule } from 'src/app/transloco/transloco-root.module';
import { PrimeNgModule } from '../shared/components/PrimeNg.module';
import { SharedCommonModule } from '../shared/sharedCommon.module';
import { AprovacaoComponent } from './aprovacao.component';
import { AprovacaoRoutingModule } from './aprovacao.routing.module';

registerLocaleData(localePt);

@NgModule({
    declarations: [
        AprovacaoComponent,
    ],
    imports: [
        AprovacaoRoutingModule,
        SharedCommonModule,
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        /* ----interface---- */
        PrimeNgModule,
        /* tradução */
        TranslocoRootModule
    ],
    exports: [
        AprovacaoComponent,
    ],
    providers: [
    ],
})

export class AprovacaoModule { }

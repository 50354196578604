import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AprovacaoComponent } from './aprovacao.component';

const AprovacaoRoutes: Routes = [
    {
        path: '',
        component: AprovacaoComponent,
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(AprovacaoRoutes),
    ],
    exports: [
        RouterModule
    ],
})

export class AprovacaoRoutingModule { }

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import jwt_decode from "jwt-decode";
import { Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { HandleErrosService } from './handleErros.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  urlService: string = undefined;
  api_ref: string = environment.API_URL;
  subject = new Subject<any>();
  user: any;
  email: any;
  perfil: any;
  userEmail: any;
  cpf: any;
  userAd: any;
  primeiro_acesso: any;

  constructor(
    public httpClient: HttpClient,
    public handleErrosService: HandleErrosService
  ) {
  }

  //define a url
  public tipoUrl(url) {
    this.urlService = url;
  }

  // responsável por fazer o login, por meio de requisição HTPP
  public post(item: any) {
    return this.httpClient.post<any>(`${this.api_ref}/${this.urlService}`, item, { observe: 'response' })
      .pipe(
        catchError(this.handleErrosService.handleError)
      );
  }

  public sso(item: any) {
    return this.httpClient.post<any>(`${this.api_ref}/usuario/autenticacao`, item, { observe: 'response' })
      .pipe(
        catchError(this.handleErrosService.handleError)
      );
  }


  // responsável por setar a sessão
  setSession(authResult) {
    const token = authResult;
    this.user = jwt_decode(token);
    this.email = jwt_decode(token);
    this.userEmail = jwt_decode(token);
    this.perfil = jwt_decode(token);
    this.cpf = jwt_decode(token);
    this.userAd = jwt_decode(token);
    this.primeiro_acesso = jwt_decode(token);

    localStorage.setItem('token', token);
    localStorage.setItem('user', this.user.givenName);
    localStorage.setItem('perfil', this.perfil.perfil);
    localStorage.setItem('email', this.email.email_diretor);
    localStorage.setItem('userEmail', this.userEmail.mail);
    localStorage.setItem('cpf', this.cpf.cpf);
    localStorage.setItem('userAd', this.userAd.sAMAccountName);
    localStorage.setItem('primeiro_acesso', this.primeiro_acesso.primeiro_acesso);
  }

  // responsável por realizar logout
  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('perfil');
    localStorage.removeItem('email');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('cpf');
    localStorage.removeItem('userAd');
    localStorage.removeItem('primeiro_acesso');
  }

  // responsável por verificar se o token é diferente de null
  isLoggedIn() {
    return (localStorage.getItem('token') !== null);
  }

  // responsável por verificar se entraram na ferramente sem realizar o login
  isLoggedOut() {
    return !this.isLoggedIn();
  }

}
